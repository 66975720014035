
@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: normal;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-Thin.woff2') format('woff2');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: expanded;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtendedThin.woff2') format('woff2'), url('TTF/Iosevka-ExtendedThin.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: normal;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ThinOblique.woff2') format('woff2'), url('TTF/Iosevka-ThinOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 100;
	font-stretch: normal;
	src: url('../../fonts/iosevka/Iosevka-ThinOblique.woff2') format('woff2'), url('TTF/Iosevka-ThinOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: expanded;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtendedThinOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedThinOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 100;
	font-stretch: expanded;
	src: url('../../fonts/iosevka/Iosevka-ExtendedThinOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedThinOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: normal;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ThinItalic.woff2') format('woff2'), url('TTF/Iosevka-ThinItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 100;
	font-stretch: expanded;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtendedThinItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtendedThinItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: normal;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtraLight.woff2') format('woff2'), url('TTF/Iosevka-ExtraLight.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: expanded;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtendedExtraLight.woff2') format('woff2'), url('TTF/Iosevka-ExtendedExtraLight.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: normal;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtraLightOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtraLightOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 200;
	font-stretch: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtraLightOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtraLightOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: expanded;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtendedExtraLightOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedExtraLightOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 200;
	font-stretch: expanded;
	src: url('../../fonts/iosevka/Iosevka-ExtendedExtraLightOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedExtraLightOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: normal;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtraLightItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtraLightItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: expanded;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtendedExtraLightItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtendedExtraLightItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-Light.woff2') format('woff2'), url('TTF/Iosevka-Light.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: expanded;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtendedLight.woff2') format('woff2'), url('TTF/Iosevka-ExtendedLight.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: normal;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-LightOblique.woff2') format('woff2'), url('TTF/Iosevka-LightOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 300;
	font-stretch: normal;
	src: url('../../fonts/iosevka/Iosevka-LightOblique.woff2') format('woff2'), url('TTF/Iosevka-LightOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: expanded;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtendedLightOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedLightOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 300;
	font-stretch: expanded;
	src: url('../../fonts/iosevka/Iosevka-ExtendedLightOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedLightOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: normal;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-LightItalic.woff2') format('woff2'), url('TTF/Iosevka-LightItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 300;
	font-stretch: expanded;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtendedLightItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtendedLightItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-Regular.woff2') format('woff2'), url('TTF/Iosevka-Regular.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: expanded;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-Extended.woff2') format('woff2'), url('TTF/Iosevka-Extended.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-Oblique.woff2') format('woff2'), url('TTF/Iosevka-Oblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	src: url('../../fonts/iosevka/Iosevka-Oblique.woff2') format('woff2'), url('TTF/Iosevka-Oblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: expanded;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtendedOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 400;
	font-stretch: expanded;
	src: url('../../fonts/iosevka/Iosevka-ExtendedOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-Italic.woff2') format('woff2'), url('TTF/Iosevka-Italic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: expanded;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtendedItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtendedItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-Medium.woff2') format('woff2'), url('TTF/Iosevka-Medium.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: expanded;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtendedMedium.woff2') format('woff2'), url('TTF/Iosevka-ExtendedMedium.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: normal;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-MediumOblique.woff2') format('woff2'), url('TTF/Iosevka-MediumOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 500;
	font-stretch: normal;
	src: url('../../fonts/iosevka/Iosevka-MediumOblique.woff2') format('woff2'), url('TTF/Iosevka-MediumOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: expanded;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtendedMediumOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedMediumOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 500;
	font-stretch: expanded;
	src: url('../../fonts/iosevka/Iosevka-ExtendedMediumOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedMediumOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: normal;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-MediumItalic.woff2') format('woff2'), url('TTF/Iosevka-MediumItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 500;
	font-stretch: expanded;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtendedMediumItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtendedMediumItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-SemiBold.woff2') format('woff2'), url('TTF/Iosevka-SemiBold.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: expanded;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtendedSemiBold.woff2') format('woff2'), url('TTF/Iosevka-ExtendedSemiBold.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: normal;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-SemiBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-SemiBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 600;
	font-stretch: normal;
	src: url('../../fonts/iosevka/Iosevka-SemiBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-SemiBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: expanded;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtendedSemiBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedSemiBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 600;
	font-stretch: expanded;
	src: url('../../fonts/iosevka/Iosevka-ExtendedSemiBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedSemiBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: normal;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-SemiBoldItalic.woff2') format('woff2'), url('TTF/Iosevka-SemiBoldItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 600;
	font-stretch: expanded;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtendedSemiBoldItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtendedSemiBoldItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: normal;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-Bold.woff2') format('woff2'), url('TTF/Iosevka-Bold.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: expanded;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtendedBold.woff2') format('woff2'), url('TTF/Iosevka-ExtendedBold.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: normal;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-BoldOblique.woff2') format('woff2'), url('TTF/Iosevka-BoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 700;
	font-stretch: normal;
	src: url('../../fonts/iosevka/Iosevka-BoldOblique.woff2') format('woff2'), url('TTF/Iosevka-BoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: expanded;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtendedBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 700;
	font-stretch: expanded;
	src: url('../../fonts/iosevka/Iosevka-ExtendedBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: normal;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-BoldItalic.woff2') format('woff2'), url('TTF/Iosevka-BoldItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 700;
	font-stretch: expanded;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtendedBoldItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtendedBoldItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: normal;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtraBold.woff2') format('woff2'), url('TTF/Iosevka-ExtraBold.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: expanded;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtendedExtraBold.woff2') format('woff2'), url('TTF/Iosevka-ExtendedExtraBold.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: normal;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtraBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtraBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 800;
	font-stretch: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtraBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtraBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: expanded;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtendedExtraBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedExtraBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 800;
	font-stretch: expanded;
	src: url('../../fonts/iosevka/Iosevka-ExtendedExtraBoldOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedExtraBoldOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: normal;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtraBoldItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtraBoldItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 800;
	font-stretch: expanded;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtendedExtraBoldItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtendedExtraBoldItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-Heavy.woff2') format('woff2'), url('TTF/Iosevka-Heavy.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: expanded;
	font-style: normal;
	src: url('../../fonts/iosevka/Iosevka-ExtendedHeavy.woff2') format('woff2'), url('TTF/Iosevka-ExtendedHeavy.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: normal;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-HeavyOblique.woff2') format('woff2'), url('TTF/Iosevka-HeavyOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 900;
	font-stretch: normal;
	src: url('../../fonts/iosevka/Iosevka-HeavyOblique.woff2') format('woff2'), url('TTF/Iosevka-HeavyOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: expanded;
	font-style: oblique;
	src: url('../../fonts/iosevka/Iosevka-ExtendedHeavyOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedHeavyOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web Oblique';
	font-display: swap;
	font-weight: 900;
	font-stretch: expanded;
	src: url('../../fonts/iosevka/Iosevka-ExtendedHeavyOblique.woff2') format('woff2'), url('TTF/Iosevka-ExtendedHeavyOblique.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: normal;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-HeavyItalic.woff2') format('woff2'), url('TTF/Iosevka-HeavyItalic.TTF') format('truetype');
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 900;
	font-stretch: expanded;
	font-style: italic;
	src: url('../../fonts/iosevka/Iosevka-ExtendedHeavyItalic.woff2') format('woff2'), url('TTF/Iosevka-ExtendedHeavyItalic.TTF') format('truetype');
}
